
import { defineComponent, onMounted, ref } from 'vue'
import ContentTable from '../components/ContentTable.vue'
import { CareerPlan } from '@/models/academic-definitions/classes/CareerPlan'
import { EducationRouteNames } from '@/router/route-names'
import { CareerPlanAPI } from '@/api/academic-definitions/career-plan-api'
export default defineComponent({
  components: {
    ContentTable
  },
  setup () {
    const careers = ref<CareerPlan[]>([])
    const loading = ref<boolean>(false)
    onMounted(async () => {
      try {
        loading.value = true
        careers.value = await CareerPlanAPI.findAll()
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    })

    return {
      loading,
      careers,
      EducationRouteNames
    }
  }
})
